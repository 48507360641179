











import { Vue, Component } from "vue-property-decorator";
import HalfmoonModule from "@/store/modules/HalmoonModule.ts";

@Component
export default class Error404 extends Vue {
  /** gets whether app is in dark mode */
  get isDarkModeEnabled(): boolean {
    return HalfmoonModule.isDarkMode;
  }
}
